// For a full list of msal.js configuration parameters,
// visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html

export const msalConfig = {
  auth: {
    clientId: "582b2e88-6cca-4418-83d2-2451801e1d26",
    authority: process.env.REACT_APP_AUTHORITY_URL,
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const webApiConfig = {
  BaseDeploymentURL: process.env.REACT_APP_WEB_API_URL,
  webAPIPairDevicePath: "/api/pair",
  webAPIHardwareIdPairPath: "/api/HardwareIdPair",
  resourceScope: "api://0ab9de21-b802-4d77-b279-1ad41ca233b4/.default",
  locationURLPath: "/api/UserLocation"
};

/**
 * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters,
 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
 */
export const loginRequest = {
  scopes: ["openid", "profile", webApiConfig.resourceScope],
};

// Add here scopes for access token to be used at the API endpoint.
export const tokenRequest = {
  scopes: [webApiConfig.resourceScope, "offline_access"],
};
